import React, { useEffect, useState } from 'react';
import LinesChart from '../../components/dashboard/LinesChart';
import BarsChart from '../../components/dashboard/BarsChart';
import PiesChart from '../../components/dashboard/PiesChart';
import { useDashboard } from '../../../hooks/tgac/useDashboard';
import { Tooltip } from '../../components/utils/Tooltip';
import { TimePicker } from '../../components/pickers/TimePicker';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../../../hooks/useModal';


export const InicioGac = () => {

    const {  
        listarSolicitudesAtendidas,
        listarDetalleIncidencia,
        detalleIncidencia,
        solicitudesAtendidas,
        listarIncidentes,
        incidentes,
        listaProximaVisita,
        visita ,
        listaEquiposListos,
        equiposListos
    } = useDashboard()
    
    const [ valoresModalIncidencias, setValoresModalIncidencias ] = useState() 
    const [ selectedBarData, setSelectedBarData] = useState(null);
    const [ incidenciaSeleccionada, setIncidenciaSeleccionada ] = useState()

    const handleBarClick = (fecha, cantidad) => {
        openModalDetalleIncidencia()
        listarDetalleIncidencia(fecha)
        setIncidenciaSeleccionada(`${fecha} (${cantidad})`)
        console.log(`Clic en la barra desde INICIOGAC: Fecha - ${fecha}, Cantidad - ${cantidad}`);
        setSelectedBarData({ fecha, cantidad });
    };

    const [isOpenModalEquipos, openModalEquipos, closeModalEquipos ] = useModal()
    const [isOpenModalDetalleIncidencia, openModalDetalleIncidencia, closeModalDetalleIncidencia ] = useModal()

    const obtenerNombreDelMes = (numeroMes) => {
        const nombresMeses = [
        { numeroMes: 1, nombre: "Enero" },
        { numeroMes: 2, nombre: "Febrero" },
        { numeroMes: 3, nombre: "Marzo" },
        { numeroMes: 4, nombre: "Abril" },
        { numeroMes: 5, nombre: "Mayo" },
        { numeroMes: 6, nombre: "Junio" },
        { numeroMes: 7, nombre: "Julio" },
        { numeroMes: 8, nombre: "Agosto" },
        { numeroMes: 9, nombre: "Setiembre" },
        { numeroMes: 10, nombre: "Octubre" },
        { numeroMes: 11, nombre: "Noviembre" },
        { numeroMes: 12, nombre: "Diciembre" },
        ];
        const idTablaEquipos = "tabla-equipos";
        const mesEncontrado = nombresMeses.find(mes => mes.numeroMes == numeroMes);
        return mesEncontrado ? mesEncontrado.nombre : "Mes";
    };


    useEffect(()=>{
        listarSolicitudesAtendidas()
        listarIncidentes()
        listaProximaVisita()
        listaEquiposListos()
    },[])



    return (
        <> 
                   


            <div className='lg:text-[15px] grid grid-flow-col rounded  h-[50px] flex justify-stretch text-2xl p-2'>
                ¡Bienvenido! - Gestión de Atención al Cliente
            </div>
            
            <div className='flex  lg:flex-col lg:flex justify-center'>
            {/* <div className='grid grid-flow-col lg:flex-col lg:flex justify-stretch'> */}

                {/* CALENDARIO DE PROXIMA VISITA */}
                <div className='bg-black border  rounded m-2 h-[120px] w-[50%] flex justify-center lg:h-[150px] lg:text-center ' >
                    <div className=' flex lg:flex-col shadow-xl shadow-gray-800 rounded text-center my-auto  cursor-pointer' data-tip data-for={'proximaVisita'}>
                            <div className='flex-col lg:flex-row flex justify-center m-2 '>
                                <span>Próxima</span>
                                <span>Capacitación</span>
                            </div>
                            <div className='border rounded h-[90px] w-[100px]  '>
                                    <div className='h-[28px] text-center text-l bg-blue-500 max-w-[100px] border lg:text-xs'>{obtenerNombreDelMes(visita?.fechaVisitaAuditoria?.split("/")[1])}</div>
                                <div className=" bg-gray-500  bg-opacity-25 h-[60px]">
                                    <div className=' text-4xl '>{visita?.fechaVisitaAuditoria?.split("/")[0]}</div>
                                    <div className='text-l mt-[-5px]'>{visita?.diaVisitaAuditoria}</div>
                                </div>
                            </div>
                    </div>

                    <Tooltip id={'proximaVisita'}>
                        <div className='flex flex-col'>
                            <span>Tipo: {visita?.tipoCapacitacion}</span>
                            <span>Motivo: {visita?.motivo}</span>
                            <span>Capacitador(a): {visita?.nomPersonaVisita}</span>
                        </div>
                    </Tooltip>
                    <div className='px-3 py-1 flex justify-center items-center'>
                        <TimePicker 
                        fecha={visita?.fechaVisitaAuditoria || ''} 
                        ></TimePicker>
                    </div>
                </div>
                {/* FIN CALENDARIO DE PROXIMA VISITA */}

                {/* EQUIPOS LISTOS */}
                <div className=' bg-black border rounded m-2 h-[120px] w-[50%] flex justify-center' onClick={()=>openModalEquipos()}>
                    <div className='text-[50px] my-auto cursor-pointer' data-tip data-for={'equiposListos'}>
                            <i className=" fa fa-mobile text-orange-500 "/>
                    </div>
                    <div className='text-xl my-auto  m-3'>
                        <div className="text-2xl text-orange-300">
                            {equiposListos?.length}
                        </div>
                        <div className="">
                            Equipos Listos
                        </div>
                    </div>
                </div>
                {/* FIN EQUIPOS LISTOS */}

            </div>



            <Tooltip id={'equiposListos'}>
                {   
                    equiposListos?.length > 0 
                    ? equiposListos?.map((el, i) => {
                        return  (
                            <div className='flex flex-col border-b'>
                                <span>CodTracking: {el?.codDispositivoTracking}</span>
                                <span>Equipo: {el?.nomEquipo}</span>
                                <span>unidad: {el?.unidad}</span>
                            </div>
                        )
                    })
                    : <div>No se encontro ningun dispositivo</div>
                } 
            </Tooltip>  


            <div className='grid grid-flow-col lg:flex-col lg:flex justify-stretch'>
                <div className='bg-black border rounded m-2 h-[300px] lg:h-auto'>
                    <LinesChart datos={solicitudesAtendidas}/>
                </div>
                
                <div className='bg-black border rounded m-2 h-[300px] lg:h-auto'>
                    <BarsChart datos={incidentes} onClick={handleBarClick} />
                </div>
            </div>

            {/* <div className='grid grid-flow-col lg:flex-col lg:flex justify-stretch'>
                <div className='bg-black border m-2 h-[300px]  '>
                    <PiesChart />
                </div> 
                <div className='bg-black border rounded m-2 h-[300px] w-[400px] lg:w-auto'>
                    <PiesChart />
                </div>
                <div className='bg-black border m-2'>
                    <Graphics />
                </div>
            </div> */}



            <Modal 
                isOpen={isOpenModalEquipos} closeModal={closeModalEquipos} action={closeModalEquipos}
                title="Lista de Equipos listos"
                spinner={{}}
                validButton={{denied:true}}
                textButtons={{denied:'Cerrar'}}
            >
                {/* <div className="flex max-h-[100px]  items-center flex-wrap justify-center"> */}
                <div className=" max-h-[350px] containerScroll lg:mt-6 mt-3 relative top-0 bottom-0 left-0 right-0 contenedor-tabla mx-2" >
                <table className=" tableResponsiveEquipos ">
                    <thead>
                        <tr>
                            {/* <th className="w-[10px]">N°</th> */}
                            <th className="w-[120px]">CODTRACKING</th>
                            <th className="w-[120px]">EQUIPO</th>
                            <th className="w-[120px]">UNIDAD</th>
                        </tr>
                    </thead>
                    <tbody>
                    {   
                        equiposListos?.length > 0 
                        ? equiposListos.map((el, i) => {
                            return (
                                <tr key={el.codDispositivoTracking} className=''>
                                    {/* <td data-label="" className='tablenum text-center w-[10px]'> {++i}</td> */}
                                    <td data-label="" className=" text-center w-[120px]"><i className='fa fa-barcode mx-5'></i>
                                        <span className='text-xl '>
                                            {el.codDispositivoTracking}
                                        </span>
                                    </td>
                                    <td data-label="" className="text-center w-[120px]"><i className='fa fa-mobile mx-5'></i> {el.nomEquipo}</td>
                                    <td data-label="" className="text-center w-[120px]"><i className='fa fa-bus mx-5'></i> {el.unidad}</td>
                                </tr>
                            )
                        })
                        : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                    }
                    </tbody> 
                </table>
            </div>
                {/* </div> */}
            </Modal>


            <Modal 
                isOpen={isOpenModalDetalleIncidencia} closeModal={closeModalDetalleIncidencia} action={closeModalDetalleIncidencia}
                title={`Detalle de Incidencias ${incidenciaSeleccionada}`}
                spinner={{}}
                validButton={{denied:true}}
                textButtons={{denied:'Cerrar'}}
            >
                <div className=" max-h-[350px] containerScroll lg:mt-6 mt-3 relative top-0 bottom-0 left-0 right-0 contenedor-tabla mx-2" >
                   
                <table className=" tableResponsiveEquipos w-[380px]">
                    <thead className=''>
                        <tr>
                            {/* <th className="w-[10px]">N°</th> */}
                            <th className="w-[120px]">N</th>
                            <th className="w-[120px]">RUTA</th>
                            <th className="w-[120px]">UNIDAD</th>
                            <th className="w-[120px]">EQUIPO</th>
                        </tr>
                    </thead>
                    <tbody>
                    {   
                        detalleIncidencia?.length > 0 
                        ? detalleIncidencia.map((di, i) => {
                            return (
                                <tr key={++i} className=' border-b border-gray-500'>
                                    {/* <td data-label="" className=" tablenum text-c enter text-xl w-[50px]"> {++i}</td> */}
                                    <td data-label="" className="tableEquipo text-center top-0">
                                        {/* <i className='fa fa-mobile mx-2'/> */}
                                        {di.nomIncidenciaEmpresaConcepto}
                                    </td>
                                    
                                    <td data-label="" className="text-center w-[300px]">

                                        <i className='fa fa-map mx-2'></i> {di.nomRuta}
                                        {/* </td>
                                    <td data-label="" className="text-center w-[150px]"> */}
                                        <i className='fa fa-bus mx-2'></i> {di.nomUnidad}</td>

                                    <td data-label="" className=" text-center w-[120px]"><i className='fa fa-comment-o mx-2'></i> {di.nomIncidenciaConcepto}</td>

                                        {/* <td data-label="" className=" text-center w-[120px]"><i className='fa fa-image mx-2'></i>
                                        </td> */}
                                </tr>
                            )
                        })
                        : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                    }
                    </tbody> 
                </table>
                   
                   
                   
                   
                   
                   
                   
                   
                   
                    
                    
                    <span>
                        {
                        
                        
                        
                        }
                    </span>
                </div>
            </Modal>
        </>
    )
}