import React from 'react';
import { ModalChat } from '../../components/modal/ModalChat';
import { useModal } from '../../../hooks/useModal';


export const Footer = () => {

    const [isOpen, openModal, closeModal] = useModal()


    return (
            <>
                <div className="hidden footer">
                    <footer>
                        {/* <div className=" chat-icon-box fixed-position notify">
                        
                            <button className="circle absolute bottom-5 right-5 text-green-500 hover:text-green-100" onClick={openModal}>
                                <i className="fa fa-comments text-4xl"></i>
                            </button>
                        </div> */}
                    </footer>
                </div>

                {/* <ModalChat
                isOpen={isOpen} closeModal={closeModal} action={{}}
                title="CHAT VIRTUAL"
                spinner={{}}
                >
                </ModalChat> */}
            </>
    )
}



