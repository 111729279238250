import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../../components/utils/ContenedorParametros';
import { Tooltip } from '../../../components/utils/Tooltip';
import { BotonNuevo } from '../../../components/buttons/BotonNuevo';
import { Modal } from '../../../components/modal/Modal';
import { DatePickerABX } from '../../../components/pickers/DatePicker';
import { BotonesEstado } from '../../../components/pages/incidentes/BotonesEstado';
import { useSolicitudes } from '../../../../hooks/tgac/registros/useSolicitudes';
import { useModal } from '../../../../hooks/useModal';


export const ListadoSolicitudesGac = () => {
    const [ startDate, setStartDate ] = useState(new Date())
    const [ endDate, setEndDate ] = useState(new Date())
    const [ isOpenModalDetalle, openModalDetalle, closeModalDetalle ] = useModal()
    const [ arrayDetalle, setArrayDetalle ] = useState([])
    const { solicitudes,tiposSolicitudes,parametrosSolicitudes, solicitudesCliente, listarcomboSolicitudes, listarComboTiposSolicitudes, listarParametrosSolicitudes, listarSolicitudesClientes,
        guardarEditarSolicitudCliente, editarValorSolicitud, plantillaSolicitud, editarValorPlantillaSolicitud, estadoBotonPintar,isOpenRegistro,openModalRegistro,closeModalRegistro } = useSolicitudes()

    const classNameInput = "containerScroll text-white h-[30px] rounded  w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const idTablaRepuestos = "tabla-repuestos";

    const [ parametrosValores , setParametrosValores ] = useState([])

    const actualizarValoresParametros = (parametro, valor)=>{
        const existeParametro = parametrosValores.findIndex(p => p.parametro == parametro);
        
        if (existeParametro !== -1) {
            const updatedData = [...parametrosValores];
            updatedData[existeParametro].valor = valor;
            setParametrosValores(updatedData);
        } else {
            const newItem = { parametro, valor };
            setParametrosValores(prevData => [...prevData, newItem]);
        }

        const concatenadoParametros = () => {
            return parametrosValores.map(item => `${item.parametro}^${item.valor}`).join('*');
        };
        editarValorSolicitud("concatenadoSolicitudParametro", concatenadoParametros())
    }

    useEffect(()=>{
        listarParametrosSolicitudes()
    },[tiposSolicitudes])


    useEffect(() => {
        listarcomboSolicitudes()
        !isOpenRegistro && listarSolicitudesClientes(0,startDate,endDate)
    }, [startDate,endDate,isOpenRegistro])

    const estadosSolicitudes = [
        { codEstado: 9,nomEstado : "PENDIENTE", abrevEstado: "PEN"},
        { codEstado: 18,nomEstado : "ATENDIDO", abrevEstado: "ATE"},
        { codEstado: 35,nomEstado : "RECHAZADO", abrevEstado: "REC"},
    ]

    const cargarDetalle = (parametrosDetalle)=>{
        console.log("DETALLE DE LA SOLICITUD ELEGIDA ->", parametrosDetalle )
        const arraySplitDetalle = parametrosDetalle.split(" / ")
        const objetoDetalle = arraySplitDetalle.map(item => {
            const [clave, valor] = item.split(":").map(str => str.trim());
            return { "clave": clave, "valor": valor };
        });
        setArrayDetalle(objetoDetalle)
        openModalDetalle()
    }
        
    return (
        <>
            <ContenedorParametros titulo='Solicitudes' tablaId={idTablaRepuestos}>   
                <div className="flex items-center">
                    <i className="mr-[10px] far fa-calendar-alt"></i>
                    <DatePickerABX className="" date={startDate} setDate={setStartDate}/>
                </div>
                <div className="flex items-center">
                    {/* <i className="mr-[10px] far fa-calendar-alt"></i> */}
                    <DatePickerABX className="" date={endDate} setDate={setEndDate}/>
                </div>
                <BotonNuevo onClick={openModalRegistro}></BotonNuevo>
                {/* <SearchTable tablaId={idTablaRepuestos}></SearchTable> */}
            </ContenedorParametros>


            <BotonesEstado listar={listarSolicitudesClientes} listaEstados={estadosSolicitudes} estadoActual={estadoBotonPintar} fechaInicio={startDate} fechaFin={endDate} ></BotonesEstado>
            <div className=" containerScroll lg:mt-6 mt-8 relative top-0 bottom-0 left-0 right-0 contenedor-tabla" >
                <table id={idTablaRepuestos} className=" tableResponsive ">
                    <thead>
                        <tr>
                            <th className="w-[10px]">N°</th>
                            <th className="w-[120px]">FECHA</th>
                            <th className="w-[120px]">SOLICITUD</th>
                            <th className="w-[15%]">TIPO</th>
                            <th className="w-[20%]">DETALLE</th>
                            <th className="w-[100px]">SOLICITANTE</th>
                            <th className="w-[100px]">RESPUESTA</th>
                            
                            {!estadoBotonPintar && <th className="w-[100px]">ESTADO</th>}
                        </tr>
                    </thead>
                    <tbody>
                    {   
                        solicitudesCliente?.length > 0 
                        ? solicitudesCliente.map((solicitudCliente, i) => {
                            return (
                                <tr key={solicitudCliente.codSolicitudEmpresa} className=''>
                                    <td data-label="" className='tablenum text-center w-[10px]'> {++i}</td>
                                    <td data-label="Fecha:" className="text-center w-[120px]">{solicitudCliente.fechaHoraSolicitud}</td>
                                    <td data-label="Solicitud:" className="text-center w-[120px]">{solicitudCliente.nomSolicitud}</td>
                                    <td data-label="Tipo:" className="text-center w-[12px]">{solicitudCliente.nomSolicitudTipo}</td>
                                    {/* className="text-center w-[30%] overflow-hidden whitespace-nowrap" */}

                                    <td 
                                        data-label="Contenido:" 
                                        className="text-center w-[200px] overflow-hidden "
                                        onClick={()=>cargarDetalle(solicitudCliente.parametrosSolicitud)}
                                    >
                                        <div className='max-w-[400px] truncate hover:text-green-500 hover:underline'>
                                            <i className='fa fa-eye mr-1'> </i>
                                             {solicitudCliente.parametrosSolicitud}
                                        </div>
                                    </td>
                                    
                                    <td data-label="Solicitante:" className="text-center w-[100px]">{solicitudCliente.usuarioSolicita}</td>
                                    <td data-label="Solicitante:" className="text-center w-[100px]">{solicitudCliente.observacionAtencion}</td>
                                    {!estadoBotonPintar && 
                                        <td className="text-center">
                                            <span className={`${solicitudCliente.codEstado == 9? 'bg-yellow-600' : solicitudCliente.codEstado== 18? 'bg-green-600':'bg-red-600'}  rounded text-xs p-1 `}>
                                                {solicitudCliente.nomEstado}
                                            </span>
                                        </td>
                                    }

                                </tr>
                            )
                        })
                        : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                    }
                    </tbody> 
                </table>
            </div>

            <Modal 
                isOpen={isOpenRegistro} closeModal={closeModalRegistro} action={guardarEditarSolicitudCliente}
                title="Registrar Solicitud"
                spinner={{}}
            >
                <div className="flex  items-center flex-wrap justify-center">
                        <div className="w-[620px] px-1">
                            <div className="flex flex-col justify-center w-full form-content ">      
                                <div className='flex  lg:flex-col justify-center w-full form-content '>
                                    
                                    <div className='lg:flex-col lg:w-full'>
                                        <label className='mx-2'>Solicitud: </label>
                                        <div className="flex items-center gap-4 w-[180px]">
                                            <select className={`${classNameInput} text-center mx-1`} defaultValue={plantillaSolicitud.codSolicitud || 0} onChange={(e) => {editarValorSolicitud("codSolicitud", e.target.value);editarValorPlantillaSolicitud("codSolicitud", e.target.value);listarComboTiposSolicitudes(e.target.value)}} >
                                                <option value="0">-- Seleccione --</option>
                                                {
                                                    solicitudes?.map(solicitud => {
                                                        return <option key={solicitud.codSolicitud} value={solicitud.codSolicitud}>{solicitud.nomSolicitud}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className='lg:flex-col lg:w-full'>
                                        <label className='mx-2'>Tipo Solicitud: </label>
                                        <div className="mx-2 center flex items-center gap-4 w-[200px]">
                                            <select className={`text-center ${classNameInput}`} defaultValue={plantillaSolicitud.codSolicitudTipo || 0} onChange={(e) => {editarValorSolicitud("codSolicitudTipo", e.target.value);editarValorPlantillaSolicitud("codSolicitudTipo", e.target.value);listarParametrosSolicitudes(e.target.value)}} >
                                                <option value="0">-- Seleccione --</option>
                                                {
                                                    tiposSolicitudes?.map(st => {
                                                        return <option key={st.codSolicitudTipo} value={st.codSolicitudTipo}>{st.nomSolicitudTipo}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='w-full text-gray-400  border-y border-[gray] text-center flex items-center'><h1 className='lg:ml-[20%] ml-[35%] '>Parametros Requeridos</h1></div>
                                
                                    <div className='mt-1 p-1 overflow-auto  max-h-[250px] h-auto flex flex-col w-full  form-content   justify-start containerScroll '>
                                    {/* <div className='mt-1 p-1 overflow-auto  max-h-[300px]  w-full     containerScroll '> */}
                                            {/* <div className='h-auto flex flex-col form-content '> */}

                                            {
                                                parametrosSolicitudes?.map(parametro => {
                                                    return  <>
                                                                <div key={parametro.codSolicitudTipoParametro} className=' lg:flex-col w-full flex lg:w-full'>
                                                                    <div className='w-[50%] bord border-gray-500 '>{parametro.nomSolicitudTipoParametro}: </div>
                                                                    <div className=" items-center w-[50%]">
                                                                        <input className={`${classNameInput} px-3 caret-input required`} 
                                                                            onChange={(e)=>actualizarValoresParametros(parametro.codSolicitudTipoParametro,e.target.value)}
                                                                            required={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                        </>
                                                
                                                })
                                            }
                                    </div>
                                    
                                </div>
                        </div>
                    
                    
                </div>
            </Modal>


            <Modal 
                isOpen={isOpenModalDetalle} closeModal={closeModalDetalle} action={closeModalDetalle}
                title="Detalle de la solicitud"
                spinner={{}}
                textButtons = {{ denied: 'Cerrar'}}
                validButton = {{confirm: false , denied: true}}
            >
                <div className="flex items-center flex-wrap justify-center">
                    <div className="w-[500px] px-1">
                        <div className="flex flex-col justify-center w-full form-content">      
                        <div className='w-full text-gray-400 border-y border-[gray] text-center flex items-center'>
                            <h1 className='lg:ml-[20%] ml-[35%]'>Detalle de parametros</h1>
                        </div>
                                                    
                        <div className='mt-1 p-1 overflow-auto max-h-[250px] h-auto flex flex-col w-full form-content justify-start containerScroll '>
                            {arrayDetalle?.map(ad => (
                            <div key={ad.codSolicitudTipoParametro} className='lg:flex-col w-full flex lg:w-full'>
                                <div className='w-[50%] border-gray-500'>{ad.clave}</div>
                                <div className='w-[20%] border-gray-500'>:</div>
                                <div className="items-center w-[50%]">{ad.valor}</div>
                            </div>
                            ))}
                        </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}