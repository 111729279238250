import React, { useState, useEffect, useRef, useContext } from 'react';
import '../../assets/css/views/login.css'
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider'
import { SocketContext } from '../../context/provider/SocketProvider'

// import { KafkaContext } from '../../context/provider/KafkaProvider';

import { notify } from '../../utils/utils'

import img1 from '../../assets/images/FACT_ELECTRONICA.png';
import img2 from '../../assets/images/icoXbest646a.png';
import img3 from '../../assets/images/icoXbest646b.png';

import { Slideshow, Slide } from '../components/utils/SliderShow';
import logo from '../../assets/images/LogoAbexa2018.png'



export const iniciarSesion = async (user, password, signIn) => {
    const response = await fetch('https://tmanwebapi.abexacloud.com/api/Configuracion/logeo?' + new URLSearchParams ({
        usuario: user,
        clave:   password
    }), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        // body: JSON.stringify({
        //     nomUsuario: user,
        //     password: password
        // })
    }).then(res => res.json());

    if (response.isValid) {
        const timeExpire = new Date(new Date().getTime() + response.content.tiempoExpiraMiliSegundos * 1000);
        // console.log("RESPONSE -> ", response.content[0] )
        signIn({
            ...response.content,
            tokenExpire: timeExpire || 1000
        });
        return true
    }

    return false
}

const useLogin = (props) => {
    const inputUsuario = useRef();
    const history = useHistory();
    const { stateUser, signIn } = useContext(UserContext);
    const { mqttConnect } = useContext(SocketContext);
    const [ user, setUser ] = useState();
    const [ password, setPassword ] = useState(); //culqi

    const [datosAcceso, setDatosAcceso] = useState(props.acceso) 

    const decifrado = datosAcceso? atob(datosAcceso) : null
    const decifradoAcceso= JSON.parse(decifrado);

    const ingresarRedireccionamiento = async () => {

        const ingreso = await iniciarSesion(decifradoAcceso?.usuario, decifradoAcceso?.clave, signIn)
        // console.log("DECIFRADO -> ", decifrado)
        if (ingreso) {
            mqttConnect()
            // localStorage.setItem('modulo',decifradoAcceso?.modulo )
            // history.push(`/${decifradoAcceso?.modulo}`)
            history.push('/tgac')
        } else {
            notify('Usuario o contraseña incorrectos', 'error')
            history.push('/login')
        }
    }


    useEffect (() =>{
        setDatosAcceso(props.acceso);
        props.acceso && ingresarRedireccionamiento()
    }, [props.acceso]);


    const ingresar = async (e) => {
        e.preventDefault();

        async function sha1(message) {
            const msgUint8 = new TextEncoder().encode(message);                      
            const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);          
            const hashArray = Array.from(new Uint8Array(hashBuffer));                 
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            return hashHex;
        }
        const passHasheada = await sha1(password);       
        const ingreso = await iniciarSesion(user, passHasheada, signIn);
        if (ingreso) {
            // mqttConnect()
            
            // history.push('/repuestos')
            history.push('/tgac')
        } else {
            notify('Usuario o contraseña incorrectos', 'error')
        }
    }

    return {
        user,
        setUser,
        password,
        setPassword,
        ingresar,
        inputUsuario,
        stateUser
    }
}

export const Login = (props) => {
    const login = useLogin(props);

    return (

        <div className="flex flex-col dark:bg-slate-900 h-screen">
            {/* BARRA SUPERIOR */}
            <div className="h-[56px] flex items-center justify-start lg:justify-center " style={{background:'#002A78'}}>
                <div className="flex-shrink-0 flex justify-center items-center" style={{ width: '250px'}}>
                    <img className="w-[90px] h-full block sm:w-52 w-auto" src={logo} alt="Workflow"/>
                </div>
            </div>
            {/* FIN BARRA SUPERIOR */}
                {/* <div className='bg-orange-500 w-full h-[50px]'>{decifrado}</div>
                <div className='bg-orange-500 w-full h-[50px]'>{decifradoJson.usuario}</div>
                <div className='bg-orange-500 w-full h-[50px]'>{decifradoJson.clave}</div> */}

                <div className="grow container flex justify-center items-center gap-x-10">
                <div className='lg:hidden container flex justify-center items-center m-0 w-[25%] h-[320px]' style={{border:'2px solid #002A78',borderRadius:'15px'}}>
                    <main >
                        <Slideshow  controles={true} autoplay={true} velocidad="1500" intervalo="4000">
                            <Slide className='flex justify-center items-center'>
                                <a href=""> <img  src={img1} className='w-[280px] h-[280px]' alt=""/> </a>
                            </Slide>
                            <Slide className='flex justify-center items-center'>
                                <a href=""> <img src={img2} className='w-[280px] h-[280px]' alt=""/> </a>
                                
                            </Slide>
                            <Slide className='flex justify-center items-center'>
                                <a href=""> <img src={img3} className='w-[280px] h-[280px]' alt=""/> </a>
                            </Slide>

                        </Slideshow>
                    </main>

                </div>

                <div className="w-[300px] mb-[90px] lg:w-[80%] flex flex-col items-center justify-between">

                    <div className="" style ={{}}>
                        <img src={logo} alt='' style={{marginBottom:'25px'}}/>
                    </div>

                    <div className="w-full border border-[#002A78] rounded" >

                        <div style={{textAlign:'center',borderBottom:'1px solid #002A78'}}>
                            <h2 className="dark:text-white text-base font-medium tracking-tight" >Inicio de Sesión</h2>
                        </div>

                        <form onSubmit={(e)=> login.ingresar(e)}>

                            <div className="form flex flex-col py-10 text-center w-full">

                                <div className="input-wrapper" style={{marginBottom:'20px'}}>
                                    <input ref={login.inputUsuario} onChange={(e) => login.setUser(e.target.value)} defaultValue={login.user} className=" caret-black	 input h-10 focus:outline-none focus:ring focus:ring-blue-500 rounded" placeholder="Usuario"/>
                                    <i className="fa fa-user input-icon" aria-hidden="true"></i>
                                </div>

                                <div className="input-wrapper" style={{}}>
                                    <input type="password" onChange={(e) => login.setPassword(e.target.value)} defaultValue={login.password} className=" caret-black input h-10 focus:outline-none focus:ring focus:ring-blue-500 rounded" placeholder="Contraseña"/>
                                    <i className="fa fa-lock input-icon" aria-hidden="true"></i>
                                </div>

                            </div>

                                <div className="content-buttons mt-4" style={{textAlign:'center'}}>
                                    <button type='submit' className="dark:bg-blue-500 hover:bg-blue-600  text-white py-1 rounded"style={{borderRadius:'15px',width:'60%',height:'40px'}}>
                                        Ingresar
                                    </button>
                                <div className='dark:text-white' style={{textAlign:'center',margin:'20px'}}>
                                    <label style={{cursor:'pointer'}}>Olvidé mi Contraseña</label>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


