import React, { useState } from 'react';
import styled from 'styled-components';
import RoomList from './RoomList';
import ChatForm from './ChatForm';
import Conversation from './Conversation';
import Navigation from './Navigation';
import SearchRooms from './SearchRooms';
import { useChat } from '../../../context/provider/ChatProvider'
// import {Description } from '.././'
// import { useChat } from '../context/ChatProvider';
// import { Description } from '../styled/Description';

const ChatAppContainer = styled.div`
    display: flex;
    background: rgba(214, 225, 232,0.2) ;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
                rgba(0, 0, 0, 0.12) 0px -12px 30px,
                rgba(0, 0, 0, 0.12) 0px 4px 6px,
                rgba(0, 0, 0, 0.17) 0px 12px 13px,
                rgba(0, 0, 0, 0.09) 0px -3px 5px;
    gap: 0;
    font-size: 0.85rem;
`;

const CenterContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 2vw 1vw;

    @media (max-width: 820px) {
        height: 80%;
    }
    
`;

const Chat = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    ${'' /* gap: 1.1em; */}
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 1em;
    height: 3.2em;
    
    & img {
        height: 50px;
        border-radius: 0.7em;
    }

    & h2 {
        font-size: 0.85em;
        font-weight: 600;
        color: rgba(0, 0, 0);

    }
`;

const WelcomeMessage = styled.p`
    margin: auto 0;
    font-size: 1.5em;
    text-align: center;
    color: rgba(255, 255, 255);
`;

const ChatContainer = () => {
    const [query, setQuery] = useState('');
    const [isNavOpen, setIsNavOpen] = useState();
    const { currentRoom } = useChat();

    return (
        <ChatAppContainer>
            {/* <Navigation openRoomNav={ () => setIsNavOpen(true) } /> */}
            <RoomList 
                query={ query }
                isNavOpen={ isNavOpen }
                setIsNavOpen={ setIsNavOpen }
            />
            <CenterContainer>
                {/* <SearchRooms query={ query } setQuery={ setQuery } /> */}

                <Chat>
                    {
                        ! currentRoom ? 
                        
                        <WelcomeMessage>¡Bienvenido a nuestro Chat!<br/> Chatea con los grupos de Abexa para recibir una atención mas personalizada.<br/> </WelcomeMessage>
                        :
                        <>
                            <Header>
                                <img alt='room-img' src={ currentRoom.src } />

                                <div>
                                    <div className='text-white text-xl'>{ currentRoom.name }</div>
                                    <div className='text-black'>{ currentRoom.description }</div>
                                </div>
                            </Header>
                            
                            <Conversation />
            
                            <ChatForm />
                        </>

                    }
                </Chat>
            </CenterContainer>

            
        </ChatAppContainer>
    );
};

export default ChatContainer;