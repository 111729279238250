import { useState, useEffect, useContext } from "react";
import { notify } from "../../../utils/utils";
import { AuthFetch } from "../../../services/api";
import { useModal } from '../../useModal';
// import { useModal } from "../useModal";
import { useSpinner } from "../../useSpinner";
import { UserContext } from "../../../context/provider/UserProvider";
// import { notEqual } from "assert";
// import { formatDate } from "../../../utils/utils";
import { format } from 'date-fns';


const urlBaseTman = process.env.REACT_APP_TMAN_API


export const useSolicitudes = () => {


// La fecha original
// const fechaOriginal = new Date("Tue Jul 18 2023 17:27:19 GMT-0500");

// Formatear la fecha a "18/07/2023"
// const fechaFormateada = format(fechaOriginal, 'dd/MM/yyyy');

// console.log(fechaFormateada); // Salida: "18/07/2023"




    const { stateUser , signOut } = useContext(UserContext);
    
    const solicitudClienteDefault = {
        codEmpresa: 0,
        codUsuarioAccion: 0,
        codSolicitud: 0,
        codSolicitudTipo: 0,
        concatenadoSolicitudParametro: ""
    }

    const plantillaSolicitudDefault = {
            codSolicitud: 0,
            nomSolicitud: "",
            codSolicitudTipo: 0,
            nomSolicitudTipo: "",
            descripcionSolicitudTipo: "",
            parametros:[
            {
                codSolicitudTipoParametro: 0,
                nomSolicitudTipoParametro: ""
            }
        ]
    }



    const [ estadoBotonPintar, setEstadoBotonPintar ] = useState(0)

    const [ solicitudes, setSolicitudes ] = useState([]);
    const [ tiposSolicitudes, setTiposSolicitudes ] = useState([]);
    const [ parametrosSolicitudes, setParametrosSolicitudes ] = useState([]);
    const [ solicitudesCliente, setSolicitudesCliente ] = useState([]);
    const [ plantillaSolicitud, setPlantillaSolicitud ] = useState(plantillaSolicitudDefault)
    const [ solicitudCliente, setSolicitudCliente ] = useState(plantillaSolicitudDefault);
    
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    const [ isOpenRegistro, openModalRegistro, closeModalRegistro] = useModal();
    

    const listarcomboSolicitudes = async () => {
        // console.log("ENTRO AL LISTARCOMBOSOLICITUDES")
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaSolicitudes'
        });
        // console.log("RESPONSE DEL LISTARCOMBO SOLICITUDES -> ", response)
        if (response.isValid){
            setSolicitudes(response.content)
        }else{
            notify(response.content,'error')
        }
    }

    const listarComboTiposSolicitudes = async (codSolicitud) => {
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaSolicitudesTipos?' + new URLSearchParams({
                codSolicitud: codSolicitud
            })
        });
        if (response.isValid){
            setTiposSolicitudes(response.content)
        }else{
            notify(response.content)
        }
    }

    const listarParametrosSolicitudes = async (codTipoSolicitud) => {
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaSolicitudesParametros?'+ new URLSearchParams({
                codSolicitudTipo: codTipoSolicitud ||0 
            })
        });
        if (response.isValid){
            setParametrosSolicitudes(response.content)
        }else{
            notify(response.content)
        }
    }

    const listarSolicitudesClientes = async (codEstado,fechaInicio,fechaFin) => {
        setEstadoBotonPintar(codEstado)

        // console.log("LISTADO DE SOLICITUDES -> ", codEstado,format(fechaInicio, 'dd/MM/yyyy'),format(fechaFin, 'dd/MM/yyyy'))
        const response = await AuthFetch({url: urlBaseTman + '/Solicitudes/listaSolicitudesClientes?'+ new URLSearchParams({
                codEmpresa:stateUser.codEmpresa,
                codEstado:codEstado,
                fechaInicio:format(fechaInicio, 'dd/MM/yyyy'),
                fechaFin:format(fechaFin, 'dd/MM/yyyy'),
            })
        });
        // console.log("RESPONSE SOLICITUDES CLIENTES  ->", response.content)
        if (response.isValid){
            setSolicitudesCliente(response.content)
        }else{
            notify(response.content,'error')
        }
    }
    
    const guardarEditarSolicitudCliente = async () => {
        
        if (!solicitudCliente.concatenadoSolicitudParametro){
            notify('Debe ingresar los parametros solicitados','error')
            return
        }

        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/registroSolicitudClientes' ,
            method:'POST', 
            body: JSON.stringify({
                codEmpresa: stateUser.codEmpresa,
                codUsuarioAccion: stateUser.codUsuario,
                codSolicitud: solicitudCliente.codSolicitud,
                codSolicitudTipo: solicitudCliente.codSolicitudTipo,
                concatenadoSolicitudParametro:solicitudCliente.concatenadoSolicitudParametro,
            })
        });
        // console.log("PARAMETROS ->", stateUser.codEmpresa,stateUser.codUsuario, solicitudCliente.codSolicitud,solicitudCliente.codSolicitudTipo,solicitudCliente.concatenadoSolicitudParametro)
        if (response.isValid){
            notify(response.content,'success');
            setSolicitudesCliente(solicitudClienteDefault);
            closeModalRegistro();
            // listarSolicitudesClientes(0)//0 todos
        }else{
            notify(response.exceptions[0].description,'error');
            
        }
    }

    const editarValorSolicitud = (key,value) =>{
        setSolicitudCliente(solicitudCliente => {
            return {
                ...solicitudCliente,
                [key]: value
            }
        });
    }     
    const editarValorPlantillaSolicitud = (key,value) =>{
        setPlantillaSolicitud(plantilla => {
            return {
                ...plantilla,
                [key]: value
            }
        });
    }     
    return {
            solicitudes,
            tiposSolicitudes,
            parametrosSolicitudes,
            solicitudesCliente,
            listarcomboSolicitudes,
            listarComboTiposSolicitudes,
            listarParametrosSolicitudes,
            listarSolicitudesClientes,
            guardarEditarSolicitudCliente,
            editarValorSolicitud,
            plantillaSolicitud,
            editarValorPlantillaSolicitud,
            estadoBotonPintar,
            isOpenRegistro,
            openModalRegistro,
            closeModalRegistro
        };
}