import { useRef, useContext } from 'react'
import { useHistory } from "react-router-dom";

import '../../../assets/css/botonNotificaciones.css'
import tGPS from '../../../assets/images/tGPS.png'
import tREC from '../../../assets/images/tREC.png'
import tSIR from '../../../assets/images/tSIR.png'
import tMAN from '../../../assets/images/icoTMan.png'
import tXlite from '../../../assets/images/icoXlite.png'
import tGAC from '../../../assets/images/tSPL.png'
// import { useMenu } from '../../../hooks/useMenu';

import { UserContext } from '../../../context/provider/UserProvider';


export const BotonMenuPrincipal = (props) => {

    const { stateUser, cambiarModulo } = useContext(UserContext)


    const dropDownNotify = useRef()
    const history = useHistory();

    const toggleNotify = () => {
        const isClosed = dropDownNotify.current.classList.contains('hidden')
        isClosed && dropDownNotify.current.classList.remove('hidden')
        !isClosed && dropDownNotify.current.classList.add('hidden')
    }

    const irGac =()=>{
        cambiarModulo("tgac")
        history.push('/tgac')
        localStorage.setItem('modulo','/tgac')
        toggleNotify()
    }


    const irTman =()=>{
        cambiarModulo("tman")
        history.push('/tman')
        localStorage.setItem('modulo','/tman')
        toggleNotify()
    
    }
    // const irTman =()=>{window.open('https://tman.abexacloud.com/')}
    // const irXlite=()=>{window.open("https://xlite.abexacloud.com/")}

    const irTgoa=()=>{window.open("https://tgoa.abexacloud.com/wSistema/wInicio/wInicio.aspx")}
    const irTrec=()=>{window.open("https://tgps.abexacloud.com/Inicio/Inicio")}
    const irTsir=()=>{window.open("https://tgps.abexacloud.com/UsuarioPerfil/_MenuPrincipal?id=QRP7SGpFIvBfWgfmxqIowQ==&nombremodulo=T-SiR")}

    return (
        <>

        <button  onClick={() => toggleNotify()} className='absolute bottom-[5px] right-[15px] fa fa-home text-[30px] hover:text-[#00FFFF] lg:right-0 '>
        </button>
    
            <div ref={dropDownNotify} className=" absolute top-[66px] right-[1px] hidden z-50 w-[150px] bg-white rounded-xl  shadow bg-gray-800 ">
                {/* <ul className="py-1 text-sm text-white-700 dark:text-white-500 text-center " aria-labelledby="dropdownButton"> */}
                <li className="block flex py-2  px-4  hover:bg-blue-900 hover:text-white rounded cursor-pointer" onClick={()=>irTgoa()}>
                    <img src={tGPS} className='m-1 w-[40px] h-[40px]' alt=""/><span className='ml-2 mt-3'> T-GOA</span>
                </li>
                {/* </ul> */}
                <li className="block flex py-2  px-4 hover:bg-blue-900 hover:bg-blue-700 hover:text-white rounded" onClick={()=>irTrec()}>
                    <img src={tREC} className='m-1 w-[40px] h-[40px]' alt=""/><span className='ml-2 mt-3'>  T-REC</span>
                </li>

                <li className="block flex py-2  px-4 hover:bg-blue-900 hover:bg-blue-700 hover:text-white rounded" onClick={()=>irTsir()}>
                    <img src={tSIR} className='m-1 w-[40px] h-[40px]' alt=""/><span className='ml-2 mt-3'>  T-SIR</span>
                </li>

                <li className="block flex py-2  px-4 hover:bg-blue-900 hover:bg-blue-700 hover:text-white rounded" onClick={()=>irTman()}>
                    <img src={tMAN} className='m-1 w-[40px] h-[40px]' alt=""/><span className='ml-2 mt-3'>  T-MAN</span>
                </li>

                <li className="block flex py-2  px-4 hover:bg-blue-900 hover:bg-blue-700 hover:text-white rounded" onClick={()=>irGac()}>
                    <img src={tGAC} className='m-1 w-[40px] h-[40px]' alt=""/><span className='ml-2 mt-3'>  T-GAC</span>
                </li>
                
                {/* <li className="block flex py-2  px-4 hover:bg-blue-900 hover:bg-blue-700 hover:text-white rounded" onClick={()=>irXlite()}>
                    <img src={tXlite} className='m-1 w-[40px] h-[40px]' alt=""/><span className='ml-2 mt-3'>  X-LITE</span>
                </li> */}

            </div> 
        </>




    )
}
























