import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../../components/utils/ContenedorParametros';
import { BotonProcesar } from '../../../components/buttons/BotonProcesar';
import { Tooltip } from '../../../components/utils/Tooltip';
import { BotonNuevo } from '../../../components/buttons/BotonNuevo';
import { Modal } from '../../../components/modal/Modal';
import { useModal } from '../../../../hooks/useModal';
import { SearchTable } from '../../../components/utils/SearchTable';
import { DatePickerABX } from '../../../components/pickers/DatePicker';


export const Mensajeria = () => {
    const [ startDate, setStartDate ] = useState(new Date())
    const [ endDate, setEndDate ] = useState(new Date())

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const [isOpen, openModal, closeModal] = useModal()
    const idTablaRepuestos = "tabla-repuestos";

    useEffect(() => {
    }, [])

    const mensajes = [
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje2",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje3",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje4",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje5",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje6",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje7",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje8",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        
    ]
        
        // tablaImportacion.push(repuestos)
        
        
    return (
        <>
            <ContenedorParametros
                titulo='Movimientos' tablaId={idTablaRepuestos}
            >   
                
                
                <div className="flex items-center">
                    <i className="mr-[10px] far fa-calendar-alt"></i>
                    <DatePickerABX className="" date={startDate} setDate={setStartDate}/>
                </div>
                <div className="flex items-center">
                    <i className="mr-[10px] far fa-calendar-alt"></i>
                    <DatePickerABX className="" date={endDate} setDate={setEndDate}/>
                </div>

                <BotonNuevo onClick={openModal}></BotonNuevo>
                {/* <BotonExportarExcel></BotonExportarExcel> */}
                <BotonProcesar></BotonProcesar>       
                
                {/* <BotonGeneral>Imprimir Listado BardCode</BotonGeneral> */}
                {/* <BotonExportarExcel excelData={tablaImportacion} fileName={"Tabla de Repuestos"}></BotonExportarExcel> */}
                {/* {fecha:"22/12/2022",Emisor: "G101-00045", Receptor: "Henry Cerna Quispe", Asunto:"ingreso por inventario",Contenido:"Nuevo", Archivo:"", Firma:""}, */}

            </ContenedorParametros>
            
            <Tooltip id={'Departamento'}>Departamento</Tooltip>
            <Tooltip id={'Provincia'}>Provincia</Tooltip>
            <Tooltip id={'Distrito'}>Distrito</Tooltip>
            <Tooltip id={'Estado'}>Estado</Tooltip>
            <Tooltip id={'Categoria'}>Categoria</Tooltip>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id={idTablaRepuestos} className="tableResponsive">
                    <thead>
                        <tr>
                            <th className="w-[20px]">N°</th>
                            <th className="w-[120px]">FECHA</th>
                            <th className="w-[150px]">EMISOR</th>
                            <th className="w-[30%]">RECEPTOR</th>
                            <th className="w-[30%px]">ASUNTO</th>
                            <th className="w-[150px]">CONTENIDO</th>
                            <th className="w-[100px]"></th>
                        </tr>
                    </thead>
                    <tbody>

                    {   
                        mensajes.length > 0 
                        ? mensajes.map((movimiento, i) => {
                            return (
                                <tr key={movimiento.cod} className=''>
                                    <td className='tablenum text-center w-[20px]'> {++i}</td>
                                    <td className="text-center w-[120px]">{movimiento.fecha}</td>
                                    <td className="text-center w-[150px]">{movimiento.Emisor}</td>
                                    <td className="text-center w-[30%]">{movimiento.Receptor}</td>
                                    <td className="text-center w-[30%]">{movimiento.Asunto}</td>
                                    <td className="text-center w-[100px]">{movimiento.Contenido}</td>
                                    <td className="tableopciones text-center w-[10px]" >
                                        
                                        <button className="bg-green-500 hover:bg-green-600 text-l m-2 px-2 py-1 rounded">
                                            <i className="fa fa-file-archive-o"></i>
                                        </button>

                                        <button className="bg-blue-500 hover:bg-blue-600 text-l px-2 py-1 rounded">
                                            <i className="fas fa-eye"></i>
                                        </button>

                                    </td>
                                </tr>
                            )
                        })
                        : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                    }
                    </tbody> 
                </table>
            </div>
            <Modal 
                isOpen={isOpen} closeModal={closeModal} action={{}}
                title="Crear Mensaje"
                spinner={{}}
            >
                <div className="flex  items-center flex-wrap justify-center">
                        <div className="w-[500px] px-4">
                            <div className="flex flex-col justify-between w-full form-content">                        
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Para: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select className={`${classNameInput} caret-input`}>
                                            <option> Atención al Cliente </option>
                                            <option> Soporte </option>
                                            <option> Administración </option>
                                            <option> Sistemas </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Asunto: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input className={`${classNameInput} caret-input`}/>
                                    </div>
                                </div>
                                <div className='lg:flex-col lg:w-full'>
                                    <label> Contenido: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <textarea className={`${classNameInput} caret-input h-[150px]`}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full text-center justify-center item-center center '>
                            {/* <img className='rounded-xl w-[200px] h-[200px] mb-1 lg:w-[150px] lg:h-[150px]' src={{}} alt='' /> */}
                            <input ref={{}} type="file" name="file-1" id="file-1" className="inputfile inputfile-1" accept='.jpg,.png'  onChange={{}} />
                            <label htmlFor="file-1">
                                <i className="fa fa-file-o iborrainputfile" style={{color: '#FFFFFF', fontSize:'18px'}}></i> 
                                <span className="iborrainputfile"> Adjuntar Archivo</span>
                            </label>
                        </div>

                </div>
            </Modal>
        </>
    )
}