import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../../../context/provider/UserProvider';
import { useHistory } from "react-router-dom";
// import LogoAbexa2018 from '../../../assets/images/LogoAbexa2018.png'
import { SocketContext } from '../../../context/provider/SocketProvider'
import { Timer } from './Timer';
import { Slideshow, Slide } from '../../components/utils/SliderShow';
import logoAbexa from '../../../assets/images/LogoAbexa-Web.png'
import logoTman from '../../../assets/images/TmanLogoCabecera.fw.png'
import { BotonNotificaciones } from '../../components/utils/BotonNotificaciones';
import { BotonMenuPrincipal } from '../../components/utils/BotonMenuPrincipal';
import { BotonMenuTGOA } from '../../components/utils/BotonMenuTGOA';

export const Header = ({openMenu}) => {
    const urlPlaminApi = process.env.REACT_APP_PLAMIN_API + '/api';

    const dropDown = useRef()
    // const dropDownNotify = useRef()
    const { stateUser, signOut } = useContext(UserContext)
    const { mqttDisconnect } = useContext(SocketContext)
    const history = useHistory();
        
    const singOutSession = () => {        
        mqttDisconnect();
        signOut()
        history.push('/')
    }

    const toggleUser = () => {
        const isClosed = dropDown.current.classList.contains('hidden')
        isClosed && dropDown.current.classList.remove('hidden')
        !isClosed && dropDown.current.classList.add('hidden')
        
        // dropDownNotify.current.classList.add('hidden')

    }
    const irPrincipal=()=>{
        history.push(localStorage.getItem('modulo'))
    }
   

    return (
        <>
            <div className="header">
                <nav>
                    <div className="width-full px-4 lg:px-2">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="flex items-center hidden lg:flex w-[110px]">
                                <button onClick={openMenu} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                    <i className="fas fa-bars text-[20px]"></i>
                                </button>
                            </div>
                            <div className="flex-1 flex items-stretch justify-start lg:items-center lg:justify-center">
                                <div className="flex-shrink-0 flex justify-center items-center w-[250px]" >
                                
                                    <main > 
                                        <Slideshow  controles={false} autoplay={true} velocidad="1500" intervalo="4000">
                                           
                                            <Slide className='flex justify-center mr-3 items-center'>
                                                <img src={logoAbexa} className='lg:w-[150px] lg:h-[50px] w-[250px] cursor-pointer  ' onClick={()=>irPrincipal()} alt=""/>
                                                
                                            </Slide>
                                            <Slide className='flex justify-center mr-3 items-center'>
                                                <img src={logoAbexa} className='lg:w-[150px] lg:h-[50px] w-[250px]  cursor-pointer' onClick={()=>irPrincipal()} alt=""/>
                                                
                                            </Slide>
                                        </Slideshow>
                                    </main>
                                </div>
                            </div>

                            <div className="lg:relative w-[180px] h-[100%] flex items-center lg:right-0 inset-auto ml-1"> 
                                

                                {/* cuando muestra el boton de tgoa */}
                                {/* <button onClick={() => toggleUser()} className="lg:right-0 lg:top-5 absolute bottom-0 right-[80px] px-1 py-1 hover:bg-blue-600 rounded"> */} 
                                {/* hasta aqui */}

                                <button onClick={() => toggleUser()} className="lg:right-0 lg:top-5 absolute bottom-0 right-[50px] px-1 py-1 hover:bg-blue-600 rounded">
                                    <span className="lg:hidden text-[15px] lg:absolute lg:border">{ stateUser.nomUsuario }</span>
                                </button>

                                <Timer></Timer>
                                <BotonMenuPrincipal></BotonMenuPrincipal>


                                <div ref={dropDown} className="absolute top-[66px] right-[10px] hidden z-50 w-44 bg-white rounded divide-y divide-blue-100 shadow dark:bg-[#003478]">
                                    <ul className="py-1 text-sm text-white-700 dark:text-white-900" aria-labelledby="dropdownButton">
                                        <li><a onClick={() => singOutSession()} className="block py-2 px-4 hover:bg-blue-100 dark:hover:bg-blue-600 dark:hover:text-white">Cerrar Sesión</a></li>
                                    </ul>
                                </div> 

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
        
}