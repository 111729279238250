import React, {useEffect ,useState} from 'react';
export const TimePicker = ({fecha}) => {
    const datoHora = fecha?.split(' ')[1]
    const horaMinuto = datoHora?.replace(/:00$/, '')

    return (
        <>
        {
            <div>
                <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
                    <circle className="outer-circle" cx="40" cy="40" r="35" fill='none'></circle>
                    <circle className="inner-circle" cx="40" cy="40" r="35" fill='none'></circle>
                    <text x="50%" y="50%" textAnchor="middle" dy="0.3em" fill="white" className='font-bold'>{horaMinuto || '00:00'}</text>
                </svg>
            </div>
        }
        </>
    )
}