import React from 'react';
import styled from 'styled-components';
// import { useChat } from '../context/ChatProvider';
import { useChat } from '../../../context/provider/ChatProvider';
// import ChatContainer from './ChatContainer';
import ChatContainer from './ChatContainer';
// import Login from './Login';
import Login from './Login';

const WrapperContainer = styled.div`
    display: grid;
    height: 100vh;
    max-height:450px;
`;

const Wrapper = () => {
    const { userName } = useChat();

    return (
        <WrapperContainer>
            {
                <ChatContainer />
            }
        </WrapperContainer>
    );
};

export default Wrapper;