import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../../components/utils/ContenedorParametros';
import { BotonProcesar } from '../../../components/buttons/BotonProcesar';
import { Tooltip } from '../../../components/utils/Tooltip';
import { BotonNuevo } from '../../../components/buttons/BotonNuevo';
import { Modal } from '../../../components/modal/Modal';
import { useModal } from '../../../../hooks/useModal';
import { SearchTable } from '../../../components/utils/SearchTable';
import { DatePickerABX } from '../../../components/pickers/DatePicker';


export const RegistroSolicitudesGac = () => {
    const [ startDate, setStartDate ] = useState(new Date())
    const [ endDate, setEndDate ] = useState(new Date())

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const [isOpen, openModal, closeModal] = useModal()
    const idTablaRepuestos = "tabla-repuestos";

    useEffect(() => {
    }, [])

    const movimientos = [
        {fecha:"20/06/2023",Modulo: "Usuario",Tipo: "Creacion", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Nuevo"},
        {fecha:"20/06/2023",Modulo: "Usuario",Tipo: "Permiso", Contenido:"Usuario: Hcerna / Permisos: registro de personas  ",estado:"Nuevo"},
        {fecha:"20/06/2023",Modulo: "Unidad",Tipo: "Cambio de Ruta", Contenido:"Padron:250 / Ruta: 4515 / Placa: ARF-457 ", estado:"Atendido"},
        {fecha:"20/06/2023",Modulo: "Unidad",Tipo: "Cambio de Padrón", Contenido:"Padron:552 / PadronCambio: 122 / Placa: AED-666 / MOTIVO: La unidad original salió de la empresa ",estado:"Proceso"},
        {fecha:"20/06/2023",Modulo: "Validadores",Tipo: "Cierre de Caja", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Rechazado"},
        {fecha:"20/06/2023",Modulo: "Gps",Tipo: "Deslogueo", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Proceso"},
        {fecha:"20/06/2023",Modulo: "validadores",Tipo: "Suspencion temporal", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Atendido"},
        {fecha:"20/06/2023",Modulo: "Validadores",Tipo: "Cierre de Caja", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Atendido"},
        
    ]
        
    const tablaImportacion = [["N°","NOMBRE","MARCA","CODIGO","U.MED","P. VENTA","P. COMPRA","CARACTERISTICAS","CATEGORIA"],movimientos]
        // tablaImportacion.push(repuestos)
        
        
    return (
        <>
            <ContenedorParametros
                titulo='Movimientos' tablaId={idTablaRepuestos}
            >   
                
                
                <div className="flex items-center">
                    <i className="mr-[10px] far fa-calendar-alt"></i>
                    <DatePickerABX className="" date={startDate} setDate={setStartDate}/>
                </div>
                <div className="flex items-center">
                    {/* <i className="mr-[10px] far fa-calendar-alt"></i> */}
                    <DatePickerABX className="" date={endDate} setDate={setEndDate}/>
                </div>
                <BotonNuevo onClick={openModal}></BotonNuevo>
                <BotonProcesar></BotonProcesar>       
                

            </ContenedorParametros>
            
            <Tooltip id={'Departamento'}>Departamento</Tooltip>
            <Tooltip id={'Provincia'}>Provincia</Tooltip>
            <Tooltip id={'Distrito'}>Distrito</Tooltip>
            <Tooltip id={'Estado'}>Estado</Tooltip>
            <Tooltip id={'Categoria'}>Categoria</Tooltip>

            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
                <table id={idTablaRepuestos} className="tableResponsive">
                    <thead>
                        <tr>
                            <th className="w-[20px]">N°</th>
                            <th className="w-[120px]">FECHA</th>
                            <th className="w-[150px]">MODULO</th>
                            <th className="w-[30%]">TIPO</th>
                            <th className="w-[30%px]">CONTENIDO</th>
                            <th className="w-[150px]">ESTADO</th>
                            {/* <th className="w-[100px]"></th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {/* {fecha:"20/06/2023",Modulo: "Validadores",Tipo: "Cierre de Caja", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Nuevo"}, */}

                    {   
                        movimientos.length > 0 
                        ? movimientos.map((movimiento, i) => {
                            return (
                                <tr key={movimiento.cod} className=''>
                                    <td data-label="" className='tablenum text-center w-[20px]'> {++i}</td>
                                    <td className="text-center w-[120px]">{movimiento.fecha}</td>
                                    <td className="text-center w-[150px]">{movimiento.Modulo}</td>
                                    <td className="text-center w-[30%]">{movimiento.Tipo}</td>
                                    <td className="text-center w-[30%]">{movimiento.Contenido}</td>
                                    <td className="text-center w-[100px]">
                                        <span  className={` cursor-pointer ${movimiento.estado=='Nuevo'?'bg-green-600': movimiento.estado=='Atendido'? 'bg-blue-500':movimiento.estado=='Proceso'? 'bg-orange-500':movimiento.estado=='Rechazado'? 'bg-red-500':''} px-2 py-[2px]  uppercase text-l font-bold`}>
                                            {movimiento.estado}
                                        </span></td>
                                    {/* <td data-label="Opciones" className="tableopciones text-center w-[10px]" >
                                        <button className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </td> */}
                                </tr>
                            )
                        })
                        : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                    }
                                
                    </tbody> 
                </table>
            </div>
            <Modal 
                isOpen={isOpen} closeModal={closeModal} action={{}}
                title="Registrar Solicitud"
                spinner={{}}
            >
                <div className="flex  items-center flex-wrap justify-center">
                        <div className="w-[500px] px-4">
                            <div className="flex flex-col justify-between w-full form-content">                        
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Modulo: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select className={`${classNameInput} caret-input`}>
                                            <option> Usuario </option>
                                            <option> Unidad </option>
                                            <option> Validadores </option>
                                            <option> GPS </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Tipo: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select className={`${classNameInput} caret-input`}>
                                            <option> Creación </option>
                                            <option> Permisos </option>
                                            <option> Activación </option>
                                            <option> Cambio de Clave </option>
                                        </select>
                                    </div>
                                </div>

                                <div>-----------------------INFORMACION REQUERIDA-------------------</div>
                                <div className='lg:flex-col lg:w-full'>
                                    <label> *DNI: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input className={`${classNameInput} caret-input`}/>
                                    </div>
                                </div>

                                <div className='lg:flex-col lg:w-full'>
                                    <label>*Nombre: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input autoFocus={true} className={`${classNameInput} caret-input`}/>
                                    </div>
                                </div>
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>*Tipo Perfil: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input  className={`${classNameInput} caret-input`}/>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    
                    
                </div>
            </Modal>
        </>
    )
}