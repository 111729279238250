import React, { useEffect, createContext, useReducer } from "react";
import { UserReducers } from '../reducers/UserReducers'
import { UserTypes } from "../types/UserTypes"

export const UserContext = createContext()

export function UserProvider(props) {
    const decifrado = atob(localStorage.getItem('pm-session') || '')
    const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);

    const initialUserState = {
        codUsuario: datosSession.codUsuario || 0,
        codEmpresa: datosSession.codEmpresa || 0,
        nomUsuario: datosSession.nomUsuario || '',
        token: datosSession.token || 'token',
        tokenExpire: datosSession.tokenExpire || 0,
        menus: datosSession.menus || [],
        permisos: datosSession.permisos || [],
        codUsuarioTipo: datosSession.codUsuarioTipo || 0,
        nomFoto: datosSession.nomFoto || '',
    }

    const [ state, dispatch ] = useReducer(UserReducers, initialUserState)

    const signIn = (objSession) => {
        var objetoSesion = objSession[0] 
        var cifrado = btoa(JSON.stringify(objetoSesion));
        localStorage.setItem('pm-session', cifrado);

        dispatch({type: UserTypes.SIGN_IN, payload: objetoSesion})
    }

    const signOut = () => {
        localStorage.clear();
        
        dispatch({type: UserTypes.SIGN_OUT})
    }

    const cambiarModulo = (nuevoModulo) => {
        // console.log("ENTRO AL CAMBIARMODULO ->", nuevoModulo)

        dispatch({type: UserTypes.CAMBIAR_MODULO, payload: {modulo:  nuevoModulo || 'tgac'}})
    
    }

    return (
        <UserContext.Provider value={{stateUser: state, signIn, signOut,cambiarModulo}}>
            {props.children}
        </UserContext.Provider>
    )
}