import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../../components/utils/ContenedorParametros';
import { BotonProcesar } from '../../../components/buttons/BotonProcesar';
import { Tooltip } from '../../../components/utils/Tooltip';
import { BotonNuevo } from '../../../components/buttons/BotonNuevo';
import { Modal } from '../../../components/modal/Modal';
import { useModal } from '../../../../hooks/useModal';
import { SearchTable } from '../../../components/utils/SearchTable';
import { DatePickerABX } from '../../../components/pickers/DatePicker';
import '../../../../assets/css/tracking.css'
import { ContenedorTabla } from '../../../components/utils/ContenedorTabla';
// import { ContenedorParametros } from '../../../components/utils/ContenedorParametros';

export const Tracking = () => {
    const [ startDate, setStartDate ] = useState(new Date())
    const [ endDate, setEndDate ] = useState(new Date())

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const [isOpen, openModal, closeModal] = useModal()
    const idTablaRepuestos = "tabla-repuestos";

    useEffect(() => {
    }, [])

    const trackings = [
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje2",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje3",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje4",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje5",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje6",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje7",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        {fecha:"22/12/2022",Emisor: "Hcerna", Receptor: "Atencion al cliente", Asunto:"Asunto del mensaje8",Contenido:"Contenido de prueba del mensaje", Archivo:"", Firma:""},
        
    ]
        
        // tablaImportacion.push(repuestos)
        
        
    return (
        <>
            <ContenedorParametros titulo='Tracking de Equipos'>

            </ContenedorParametros>
            <ContenedorTabla>

            <div className=" containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla" >
                <table id={idTablaRepuestos} className=" tableResponsive ">
                    <thead>
                        <tr>
                            <th className="w-[20px]">N°</th>
                            <th className="w-[120px]">FECHA</th>
                            <th className="w-[150px]">MODULO</th>
                            <th className="w-[30%]">TIPO</th>
                            <th className="w-[30%px]">CONTENIDO</th>
                            {/* <th className="w-[150px]">ESTADO</th> */}
                            {/* <th className="w-[100px]"></th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {/* {fecha:"20/06/2023",Modulo: "Validadores",Tipo: "Cierre de Caja", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Nuevo"}, */}

                    {   
                        trackings.length > 0 
                        ? trackings.map((tracking, i) => {
                            return (
                                <tr key={tracking.cod} className=''>
                                    <td data-label="" className='tablenum text-center w-[20px]'> {++i}</td>
                                    <td className="text-center w-[120px]">{tracking.fecha}</td>
                                    <td className="text-center w-[150px]">{tracking.Modulo}</td>
                                    <td className="text-center w-[30%]">{tracking.Tipo}</td>
                                    <td className="text-center w-[30%]">
                                        
                                        <ol className="progress-tracker  ">
                                            <li className="completed"><span>Ingreso de Equipo</span></li>
                                            <li className="completed"><span>Devivación al área</span></li>
                                            <li className="completed"><span>Asignación del diagnosticador</span></li>
                                            <li className="completed"><span>Realizar Diagnostico</span></li>
                                            <li className="completed"><span>Proforma</span></li>
                                            <li className="completed"><span>Asignacion de mantenimiento</span></li>
                                            <li className="completed"><span>Devolución a cliente</span></li>
                                        </ol>

                                        
                                    </td>


                                





                                    {/* <td className="text-center w-[100px]">
                                        <span  className={` cursor-pointer ${movimiento.estado=='Nuevo'?'bg-green-600': movimiento.estado=='Atendido'? 'bg-blue-500':movimiento.estado=='Proceso'? 'bg-orange-500':movimiento.estado=='Rechazado'? 'bg-red-500':''} px-2 py-[2px]  uppercase text-l font-bold`}>
                                            {movimiento.estado}
                                        </span></td> */}
                                    {/* <td data-label="Opciones" className="tableopciones text-center w-[10px]" >
                                        <button className="bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </td> */}
                                </tr>
                            )
                        })
                        : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                    }
                                
                    </tbody> 
                </table>
            </div>





{/* -------------------------------------- */}


            <div className='w-full '>
                <main className=' w-full'>
                    <div className='  rounded text-l'>
                        <div className='bg-green-800 text-xl font-bold'>CODTRACKING: 4169 </div>
                        <div>DISPOSITIVO: VALIDADOR - GAVETA   </div>
                        <div>FECHA: 20/06/2023  </div>
                        <div>TIEMPO: 5 DIAS </div>
                    </div>

                    <ol className="progress-tracker  ">
                        <li className="completed"><span>Ingreso de Equipo</span></li>
                        <li className="completed"><span>Devivación al área</span></li>
                        {/* <li className="skipped"><span>Devivación al área</span></li> */}
                        <li className="completed"><span> Asignación del diagnosticador</span></li>
                        <li className="completed"><span>Realizar Diagnostico</span></li>
                        {/* <li className="current"><span>Realizar Diagnostico</span></li> */}
                        <li className="completed"><span>Proforma</span></li>
                        <li className="completed"><span>Asignacion de mantenimiento</span></li>
                        <li className="completed"><span>Devolución a cliente</span></li>
                    </ol>
                    
                    <hr className="mt-5 mb-8" />
                    <div className='  rounded text-l'>
                        <div className='bg-orange-800 text-xl font-bold'>CODTRACKING: 4592 </div>
                        <div>DISPOSITIVO: TGL-MPOS   </div>
                        <div>FECHA: 20/06/2023  </div>
                        <div>TIEMPO:2 DIAS </div>
                    </div>
                    <ol className="progress-tracker v2 ">
                        <li className="completed"><span>Ingreso de Equipo</span></li>
                        <li className="completed"><span>Devivación al área</span></li>
                        <li className="completed"><span> Asignación del diagnosticador</span></li>
                        <li className="completed"><span>Realizar Diagnostico</span></li>
                        <li className="current"><span>Proforma</span></li>
                        <li className=""><span>Asignacion de mantenimiento</span></li>
                        <li className=""><span>Devolución a cliente</span></li>
                    </ol>
                    
                    <hr className="mt-5 mb-8" />
                    
                    <div className='  rounded text-l'>
                        <div className='bg-green-800 text-xl font-bold'>CODTRACKING: 3895 </div>
                        <div>DISPOSITIVO: FUENTE TRIPLE   </div>
                        <div>FECHA: 20/06/2023  </div>
                        <div>TIEMPO: 3 DIAS </div>
                    </div>
                    <ol className="progress-tracker icon">
                        <li className="completed"><span>Ingreso de Equipo</span></li>
                        <li className="completed"><span>Devivación al área</span></li>
                        {/* <li className="skipped"><span>Devivación al área</span></li> */}
                        <li className="completed"><span> Asignación del diagnosticador</span></li>
                        <li className="completed"><span>Realizar Diagnostico</span></li>
                        {/* <li className="current"><span>Realizar Diagnostico</span></li> */}
                        <li className="completed"><span>Proforma</span></li>
                        <li className="completed"><span>Asignacion de mantenimiento</span></li>
                        <li className="completed"><span>Devolución a cliente</span></li>
                    </ol>

                    <hr className="mt-5 mb-8" />
                    <div className='  rounded text-l'>
                        <div className='bg-orange-800 text-xl font-bold'>CODTRACKING: 3599 </div>
                        <div>DISPOSITIVO: IMPRESORA   </div>
                        <div>FECHA: 20/06/2023  </div>
                        <div>TIEMPO: 1 DIAS </div>
                    </div>
                    <ol className="progress-tracker icon">
                        <li className="completed"><span>Ingreso de Equipo</span></li>
                        <li className="completed"><span>Devivación al área</span></li>
                        <li className="completed"><span> Asignación del diagnosticador</span></li>
                        <li className="completed"><span>Realizar Diagnostico</span></li>
                        <li className="skipped dotted"><span>Proforma (RECHAZADA)</span></li>
                        <li className="skipped "><span>Asignacion de mantenimiento</span></li>
                        <li className="current "><span>Devolución a cliente</span></li>
                        
                    </ol>
                    <hr className="mt-5 mb-8" />
                    <div className='  rounded text-l'>
                        <div className='bg-orange-800 text-xl font-bold'>CODTRACKING: 3889 </div>
                        <div>DISPOSITIVO: HUB   </div>
                        <div>FECHA: 20/06/2023  </div>
                        <div>TIEMPO: 7 DIAS </div>
                    </div>
                    <ol className="progress-tracker icon">
                        <li className="completed"><span>Ingreso de Equipo</span></li>
                        <li className="completed"><span>Devivación al área</span></li>
                        <li className="completed"><span> Asignación del diagnosticador</span></li>
                        <li className="completed"><span>Realizar Diagnostico</span></li>
                        <li className="skipped dotted"><span>Proforma (RECHAZADA)</span></li>
                        <li className="skipped "><span>Asignacion de mantenimiento</span></li>
                        <li className="current "><span>Devolución a cliente</span></li>
                        
                    </ol>
                    <hr className="mt-5 mb-8" />
                </main>
            </div>
            </ContenedorTabla>

        </>
    )
}