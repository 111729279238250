import { UserTypes } from "../types/UserTypes"

export const UserReducers = (state, action) => {
    switch (action.type) {
        case UserTypes.SIGN_IN:
            return {
                ...state,
                ...action.payload
            }
        case UserTypes.SIGN_OUT:
            return {
                ...state,
                codUsuario: 0,
                nomUsuario: '',
                token: '',
                tokenExpire: 0,
                menus: [],
                permisos: [],
                nomFoto: '',
                modulo:''
            }
        case UserTypes.CAMBIAR_MODULO:
            // console.log("REDUCER -> ",action.payload.modulo)
            return {
                ...state,
                modulo: action.payload.modulo
            }
        
        default:
            throw new Error()
    }
}
