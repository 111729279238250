import '../../../assets/css/components/botonnuevo.css'

export const BotonNuevo = (props) => {
    return (
        <button {...props} className=" btn-circle btn-xl botonNuevo">
            <i className="fa fa-plus text-[#ffffff] text-[18px] lg:text-[20px]" ></i> 
            <span className='lg:hidden text-[14px] text-[#ffffff] ml-[3px] lg:ml-0'>{props.children || 'Nuevo'}</span>
        </button>
    )
}

