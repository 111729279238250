import { useState, useEffect, useContext } from "react";
import { notify } from "../../utils/utils";
// import { notify } from "../../../utils/utils";
import { AuthFetch } from "../../services/api";
import { useModal } from '../useModal';
// import { useModal } from "../useModal";
import { useSpinner } from "../useSpinner";
import { UserContext } from "../../context/provider/UserProvider";
// import { notEqual } from "assert";
// import { formatDate } from "../../../utils/utils";
import { format } from 'date-fns';


const urlBaseTman = process.env.REACT_APP_TMAN_API


export const useDashboard = () => {

    const { stateUser , signOut } = useContext(UserContext);
    
    // const solicitudClienteDefault = {
    //     codEmpresa: 0,
    //     codUsuarioAccion: 0,
    //     codSolicitud: 0,
    //     codSolicitudTipo: 0,
    //     concatenadoSolicitudParametro: ""
    // }

    // const plantillaSolicitudDefault = {
    //         codSolicitud: 0,
    //         nomSolicitud: "",
    //         codSolicitudTipo: 0,
    //         nomSolicitudTipo: "",
    //         descripcionSolicitudTipo: "",
    //         parametros:[
    //         {
    //             codSolicitudTipoParametro: 0,
    //             nomSolicitudTipoParametro: ""
    //         }
    //     ]
    // }


    const [ solicitudesAtendidas, setSolicitudesAtendidas ] = useState([]);
    const [ incidentes, setIncidentes ] = useState([]);
    const [ visita, setVisita ] = useState([]);
    const [ equiposListos, setEquiposListos ] = useState([]);
    const [ detalleIncidencia, setDetalleIncidencia ] = useState([])
    
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    const [ isOpenRegistro, openModalRegistro, closeModalRegistro] = useModal();
    
    const listarIncidentes = async (fechaInicio, fechaFin) => {
        const currentDate = new Date(); // Obtiene la fecha actual
        currentDate.setDate(currentDate.getDate() - 6); // Resta dos días
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaIncidentes?'+ new URLSearchParams({
                CodEmpresa: stateUser.codEmpresa ,
                FechaInicio:fechaInicio || format(currentDate, 'dd/MM/yyyy'),
                FechaFinal:fechaFin || format(new Date(), 'dd/MM/yyyy')
            })
        });

        if (response.isValid){
            setIncidentes(response.content)
        }else{
            notify(response.exceptions[0].description, 'error')
        }
    }

    const listarSolicitudesAtendidas = async (fechaInicio, fechaFin) => {
        const currentDate = new Date(); // Obtiene la fecha actual
        currentDate.setDate(currentDate.getDate() - 6); // Resta dos días
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaSolicitudesAtendidas?'+ new URLSearchParams({
                CodEmpresa: stateUser.codEmpresa ,
                FechaInicio:fechaInicio || format(currentDate, 'dd/MM/yyyy'),
                FechaFinal:fechaFin || format(new Date(), 'dd/MM/yyyy')
            })
        });
        if (response.isValid){
            setSolicitudesAtendidas(response.content)
        }else{
            notify(response.content)
        }
    }



    const listarDetalleIncidencia = async (fecha) => {
            console.log("ENTRO A DETALLE INCIDENCIA ", fecha)
        // const currentDate = new Date(); // Obtiene la fecha actual
        // currentDate.setDate(currentDate.getDate() - 6); // Resta dos días
       
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaSolicitudDetallado?'+ new URLSearchParams({
                CodEmpresa: stateUser.codEmpresa ,
                // Fecha:fecha || format(new Date(), 'dd/MM/yyyy')
                Fecha:fecha
            })
        });
        console.log("RESPONSE DETALLE DE INCIDENCIA -> ", response)
        if (response.isValid){
            setDetalleIncidencia(response.content)
        }else{
            notify(response.content)
        }
    }

    const listaProximaVisita = async (fechaInicio, fechaFin) => {
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaProximaVisita?'+ new URLSearchParams({
                CodEmpresa: stateUser.codEmpresa ,
            })
        });
        if (response.isValid){
            setVisita(response.content[0])
        }else{
            notify(response.content)
        }
    }

    const listaEquiposListos = async (fechaInicio, fechaFin) => {
        const response = await AuthFetch({
            url: urlBaseTman + '/Solicitudes/listaEquiposListos?'+ new URLSearchParams({
                CodEmpresa: stateUser.codEmpresa ,
            })
        });
        if (response.isValid){
            setEquiposListos(response.content)
        }else{
            notify(response.content)
        }
    }






    // const listarcomboSolicitudes = async () => {
    //     // console.log("ENTRO AL LISTARCOMBOSOLICITUDES")
    //     const response = await AuthFetch({
    //         url: urlBaseTman + '/Solicitudes/listaSolicitudes'
    //     });
    //     // console.log("RESPONSE DEL LISTARCOMBO SOLICITUDES -> ", response)
    //     if (response.isValid){
    //         setSolicitudes(response.content)
    //     }else{
    //         notify(response.content)
    //     }
    // }

    // const listarComboTiposSolicitudes = async (codSolicitud) => {
    //     const response = await AuthFetch({
    //         url: urlBaseTman + '/Solicitudes/listaSolicitudesTipos?' + new URLSearchParams({
    //             codSolicitud: codSolicitud
    //         })
    //     });
    //     if (response.isValid){
    //         setTiposSolicitudes(response.content)
    //     }else{
    //         notify(response.content)
    //     }
    // }

    // const listarParametrosSolicitudes = async (codTipoSolicitud) => {
    //     const response = await AuthFetch({
    //         url: urlBaseTman + '/Solicitudes/listaSolicitudesParametros?'+ new URLSearchParams({
    //             codSolicitudTipo: codTipoSolicitud ||0 
    //         })
    //     });
    //     if (response.isValid){
    //         setParametrosSolicitudes(response.content)
    //     }else{
    //         notify(response.content)
    //     }
    // }

    // const listarSolicitudesClientes = async (codEstado,fechaInicio,fechaFin) => {
    //     setEstadoBotonPintar(codEstado)

    //     // console.log("LISTADO DE SOLICITUDES -> ", codEstado,format(fechaInicio, 'dd/MM/yyyy'),format(fechaFin, 'dd/MM/yyyy'))
    //     const response = await AuthFetch({url: urlBaseTman + '/Solicitudes/listaSolicitudesClientes?'+ new URLSearchParams({
    //             codEmpresa:stateUser.codEmpresa,
    //             codEstado:codEstado,
    //             fechaInicio:format(fechaInicio, 'dd/MM/yyyy'),
    //             fechaFin:format(fechaFin, 'dd/MM/yyyy'),
    //         })
    //     });
    //     // console.log("RESPONSE SOLICITUDES CLIENTES  ->", response.content)
    //     if (response.isValid){
    //         setSolicitudesCliente(response.content)
    //     }else{
    //         notify(response.content)
    //     }
    // }
    
    // const guardarEditarSolicitudCliente = async () => {
        
    //     if (!solicitudCliente.concatenadoSolicitudParametro){
    //         notify('Debe ingresar los parametros solicitados','error')
    //         return
    //     }

    //     const response = await AuthFetch({
    //         url: urlBaseTman + '/Solicitudes/registroSolicitudClientes' ,
    //         method:'POST', 
    //         body: JSON.stringify({
    //             codEmpresa: stateUser.codEmpresa,
    //             codUsuarioAccion: stateUser.codUsuario,
    //             codSolicitud: solicitudCliente.codSolicitud,
    //             codSolicitudTipo: solicitudCliente.codSolicitudTipo,
    //             concatenadoSolicitudParametro:solicitudCliente.concatenadoSolicitudParametro,
    //         })
    //     });
    //     // console.log("PARAMETROS ->", stateUser.codEmpresa,stateUser.codUsuario, solicitudCliente.codSolicitud,solicitudCliente.codSolicitudTipo,solicitudCliente.concatenadoSolicitudParametro)
    //     if (response.isValid){
    //         notify(response.content,'success');
    //         setSolicitudesCliente(solicitudClienteDefault);
    //         closeModalRegistro();
    //         // listarSolicitudesClientes(0)//0 todos
    //     }else{
    //         notify(response.exceptions[0].description,'error');
            
    //     }
    // }

    // const editarValorSolicitud = (key,value) =>{
    //     setSolicitudCliente(solicitudCliente => {
    //         return {
    //             ...solicitudCliente,
    //             [key]: value
    //         }
    //     });
    // }     
    // const editarValorPlantillaSolicitud = (key,value) =>{
    //     setPlantillaSolicitud(plantilla => {
    //         return {
    //             ...plantilla,
    //             [key]: value
    //         }
    //     });
    // }     
    return {  
        listarSolicitudesAtendidas,
        listarDetalleIncidencia,
        detalleIncidencia,
        solicitudesAtendidas,
        listarIncidentes,
        incidentes,
        listaProximaVisita,
        visita ,
        listaEquiposListos,
        equiposListos     
    };
}