import React from 'react';
import { useEstados } from '../../../../hooks/useEstados';
import { primeraLetraMayuscula } from '../../../../utils/utils' 

export const BotonesEstado = ({listar, estadoActual,fechaInicio,fechaFin,listaEstados }) => {
    const [ estados ] = useEstados();
    
    const listadoEstados = listaEstados? listaEstados : estados

    return (
        <div className='absolute z-10000000'>
                {
                    listadoEstados.map (e => {
                        let color = 'text-green-400'
                        if (e.codEstado === 18){///Nuevo
                            color = 'text-green-400'
                        } else if(e.codEstado === 9){//Proceso
                            color = 'text-orange-400'
                        }else if (e.codEstado === 0){//Atendido
                            color= 'text-blue-500'
                        }else if (e.codEstado === 35){//Rechazado
                            color= 'text-red-700'
                        }
                        return <button 
                                    onClick={() => listar(e.codEstado,fechaInicio,fechaFin)} 
                                    key={e.codEstado} 
                                    style={{boxShadow:'rgba(51, 111, 187, 0.5) 0px 30px 60px -12px inset, #336fbb 0px 18px 36px -18px inset',border:'1px solid #336fbb'}}  
                                    className={`lg:text-xs ${estadoActual === e.codEstado ? ' bg-[#2e5289] ':'  '}]   hover:bg-[#2e5289] mr-[1px] px-1 py-1 lg:px-2 lg:py-1 `}
                                >
                                    <i className={`mx-1 fa fa-circle ${color} text-l`}></i>
                                    {primeraLetraMayuscula(e.nomEstado)}
                                </button>
                    })
                }
                <button 
                    onClick={() => listar(0,fechaInicio,fechaFin)} 
                    style={{boxShadow:'rgba(51, 111, 187, 0.5) 0px 30px 60px -12px inset, #336fbb 0px 18px 36px -18px inset',border:'1px solid #336fbb'}}
                    className={`lg:text-xs  ${estadoActual === 0 ? ' bg-[#2e5289] ':'    '}]   hover:bg-[#2e5289] mr-[2px] px-1 py-1 lg:px-2 lg:py-1`}
                >
                    <i className="mx-1 fa fa-circle text-[#bcbdbf] rounded-xl text-l" ></i>
                    Todos
                </button>       
        </div>  
    )
} 
