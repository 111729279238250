import React, { useContext, useState, useLazyRef, lazy, useMemo, useEffect } from 'react'
import { Switch, Route } from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';


import { LayoutMaster } from '../modules/layout/LayoutMaster';
import { PrivateRoute } from '../components/PrivateRoute';

import PageNotFound from '../pages/PageNotFound';

// MODULO DE GAC
import { InicioGac } from '../pages/TGAC/InicioGac';
import { RegistroSolicitudesGac } from '../pages/TGAC/Solicitudes/RegistroSolicitudesGac';
// import { ListadoSolicitudesGac } from '../pages/TGAC/Solicitudes/ListadoSolicitudesGac';
import { ListadoSolicitudesGac } from '../pages/TGAC/Registros/ListadoSolicitudesGac';
import { Mensajeria } from '../pages/TGAC/Solicitudes/Mensajeria';
import { ListadoIncidentes } from '../pages/TGAC/Registros/ListadoIncidentes';
import { RegistroIncidentes } from '../pages/TGAC/Solicitudes/RegistroIncidentes';
import { Tracking } from '../pages/TGAC/Solicitudes/Tracking';
import { ListadoRequerimientosGac } from '../pages/TGAC/Registros/ListadoRequerimientoGac';

export function PrivateRoutes({ token }) {
    // const { stateUser } = useContext(UserContext);
    return (
        <>
            {                
                token !== '' && localStorage.getItem('currentLocation') !== '/'
                && (
                    <>
                        <LayoutMaster>
                            <Switch>

                                <PrivateRoute path='/tgac' component={InicioGac} />
                                <PrivateRoute path='/gac-listado-incidentes' component={ListadoIncidentes} />
                                <PrivateRoute path='/gac-registro-incidentes' component={RegistroIncidentes} />
                                <PrivateRoute path='/gac-registro-solicitudes' component={RegistroSolicitudesGac} />
                                <PrivateRoute path='/gac-listado-solicitudes' component={ListadoSolicitudesGac} />
                                <PrivateRoute path='/gac-registro-requerimientos' component={ListadoRequerimientosGac} />
                                <PrivateRoute path='/gac-mensajeria' component={Mensajeria} />
                                <PrivateRoute path='/gac-tracking' component={Tracking} />

                                <Route path="*" component={PageNotFound} />
                            </Switch>
                        </LayoutMaster>
                    </>
                )
            }            
        </>
    )
}