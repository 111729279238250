import React, { useContext } from 'react'
import { Switch, Route, Redirect, useLocation,BrowserRouter } from "react-router-dom";

// import { Web } from '../pages/Web'
import { Login } from '../pages/Login'
// import { LoginGestion } from '../pages/gestion/LoginGestion'


export function PublicRoutes() {
    return (
        <>
            <Switch>             
                <Route exact path='/' component={Login} />  
                {/* <Route exact path='/Login' component={Login} />   */}
                {/* <Route exact path='*' component={Login} />   */}
                {/* <Route exact path='/LoginGestion' component ={LoginGestion}/> */}
                
                
                <Route exact path="/login" component={Login} />
                

                <Route path="/login/:acceso" render={({ match }) => {
                    return <Login acceso={match.params.acceso} />
                }} />
            </Switch>
        </>
    )
}