import React, { useEffect, useMemo, useRef, useReducer, useState } from 'react';
import { ContenedorParametros } from '../../../components/utils/ContenedorParametros';
import { BotonProcesar } from '../../../components/buttons/BotonProcesar';
import { Tooltip } from '../../../components/utils/Tooltip';
import { BotonNuevo } from '../../../components/buttons/BotonNuevo';
import { Modal } from '../../../components/modal/Modal';
import { useModal } from '../../../../hooks/useModal';
import { SearchTable } from '../../../components/utils/SearchTable';
import { DatePickerABX } from '../../../components/pickers/DatePicker';
import imagenDefault from '../../../../assets/images/iconosubirfoto.png'



export const RegistroIncidentes = () => {
    const [ startDate, setStartDate ] = useState(new Date())
    const [ endDate, setEndDate ] = useState(new Date())

    const classNameInput = "containerScroll text-white h-[30px] rounded px-4 w-full bg-zinc-800 text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const [isOpen, openModal, closeModal] = useModal()
    const idTablaRepuestos = "tabla-repuestos";

    useEffect(() => {
    }, [])

    const movimientos = [
        {fecha:"20/06/2023",Modulo: "Usuario",Tipo: "Creacion", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Nuevo"},
        {fecha:"20/06/2023",Modulo: "Usuario",Tipo: "Permiso", Contenido:"Usuario: Hcerna / Permisos: registro de personas  ",estado:"Nuevo"},
        {fecha:"20/06/2023",Modulo: "Unidad",Tipo: "Cambio de Ruta", Contenido:"Padron:250 / Ruta: 4515 / Placa: ARF-457 ", estado:"Atendido"},
        {fecha:"20/06/2023",Modulo: "Unidad",Tipo: "Cambio de Padrón", Contenido:"Padron:552 / PadronCambio: 122 / Placa: AED-666 / MOTIVO: La unidad original salió de la empresa ",estado:"Proceso"},
        {fecha:"20/06/2023",Modulo: "Validadores",Tipo: "Cierre de Caja", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Rechazado"},
        {fecha:"20/06/2023",Modulo: "Gps",Tipo: "Deslogueo", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Proceso"},
        {fecha:"20/06/2023",Modulo: "validadores",Tipo: "Suspencion temporal", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Atendido"},
        {fecha:"20/06/2023",Modulo: "Validadores",Tipo: "Cierre de Caja", Contenido:"DNI: 46878787 / Nombre: Henry Cerna / Tipo de Perfil: Administrador  ",estado:"Atendido"},
        
    ]
        
    const tablaImportacion = [["N°","NOMBRE","MARCA","CODIGO","U.MED","P. VENTA","P. COMPRA","CARACTERISTICAS","CATEGORIA"],movimientos]
        // tablaImportacion.push(repuestos)
        
        
    return (
        <>
                <div className='w-full text-center'>
                    <div className='m-5 mb-8 text-xl'> Registrar de Incidencia </div>
                    <div className="flex  items-center flex-wrap justify-center">
                        <div className="w-auto px-4">
                            <div className="flex flex-col justify-between w-full form-content">                        
                                <div className='lg:flex-col lg:w-full'>
                                    <label>RUTA: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select className={`${classNameInput} caret-input`}>
                                            <option> -- 1611 -- </option>
                                            <option> -- 8525 -- </option>
                                            <option> -- 1514 -- </option>
                                        </select>                                    </div>
                                </div>
                                <div className='lg:flex-col lg:w-full'>
                                    <label>UNIDAD: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input className={`${classNameInput} caret-input`}/>
                                    </div>
                                </div>
                                <div className='lg:flex-col lg:w-full'>
                                    <label>N.CONTACTO: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input autoFocus={true} className={`${classNameInput} caret-input`}/>
                                    </div>
                                </div>
                                <div className='lg:flex-col lg:w-full'>
                                    <label>PRODUCTO: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select className={`${classNameInput} caret-input`}>
                                            <option>GPS</option>
                                            <option>VALIDADOR</option>
                                            <option>SISTEMA</option>
                                        </select>
                                    </div>
                                </div>


                                <div className='lg:flex-col lg:w-full'>
                                    <label>INCIDENCIA: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select className={`${classNameInput} caret-input`}>
                                            <option>Pantalla Congelada</option>
                                            <option>Gps modo avión</option>
                                            <option>Batería hinchada o descarga</option>
                                            <option>Minimización de aplicativo</option>
                                            <option>Reinicio constante en ruta</option>
                                            <option>Desconfiguración de APK</option>
                                            <option>Problemas de audio</option>
                                            <option>Cable de alimentación desoldado</option>
                                            <option>Fuente de poder averiada</option>
                                            <option>Equipos desajustados</option>
                                            <option>Falla de pick por tiempo de carga</option>
                                            <option>Cambio de cableado eléctrico</option>
                                        </select>                                   
                                    </div>
                                </div>
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>COMENTARIO: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <textarea  className={`${classNameInput} caret-input h-[150px]`}></textarea>
                                    </div>
                                </div>
                                
                        </div>
                    
                        <div className='w-full text-center justify-center item-center center '>
                            <img className='rounded-xl w-[200px] h-[200px] mb-1 lg:w-[150px] lg:h-[150px]' src={imagenDefault} alt='' />
                            <input ref={{}} type="file" name="file-1" id="file-1" className="inputfile inputfile-1" accept='.jpg,.png'  onChange={{}} />
                            <label htmlFor="file-1">
                                <i className="fa fa-image iborrainputfile" style={{color: '#FFFFFF', fontSize:'18px'}}></i> 
                                <span className="iborrainputfile"> Seleccione Imagen</span>
                            </label>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}