import React, { forwardRef } from 'react';

import DatePicker, { registerLocale, setDefaultLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es)

export const DatePickerABX = ({
    date,
    setDate,
    styles
}) => {

    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <input 
            onClick={onClick} 
            ref={ref} 
            className="text-center text-white  h-[30px] rounded px-4 lg:text-xs lg:w-[100px] w-[150px] bg-zinc-800 text-lg focus:outline-none focus:ring-1 focus:ring-blue-500" 
            defaultValue={value} 
            style={styles} 
            readOnly
        />
    ));

    return (
        <DatePicker
            locale="es" 
            selected={date}
            onChange={(date) => setDate(date)}
            customInput={<DateInput/>} 
            dateFormat="dd-MM-yyyy"
        ></DatePicker>
    )
}