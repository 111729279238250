import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useChat } from '../../../context/provider/ChatProvider';
import useChatActions from '../../../hooks/chat/useChatActions';
import useDebounce from '../../../hooks/chat/useDebounce';

const RoomListContainer = styled.div`
    background: #000000 ;
    color: #ffffff;
    ${'' /* border:red solid 2px; */}

`;

const RoomItem = styled.li`
    display: flex;
    padding: 8px;
    background: ${ props => props.active ?  '#2A589B' : 'transparent'};
    cursor: pointer;
    transition: all .05s;
    margin-top:2px;

    &:hover {
        background: #2A589B;
    }
    
    & img {
        margin-top:10px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    
    & div {

        padding-top: 20px;   
    }
    
    & span {
        padding-left: 10px;   

        color: #ffffff;
        font-weight: 500;
        font-size: 0.8em;
    }
`;


// Static rooms in the chat
const rooms = [
    {
        id: 1,
        name: 'ATENCION AL CLIENTE',
        src: 'https://cdn-icons-png.flaticon.com/512/2706/2706950.png',
        description: ''
    },

    {
        id: 2,
        name: 'SISTEMAS',
        src: 'https://cdn-icons-png.flaticon.com/512/4370/4370748.png',
        description: ''
    },
    {
        id: 3,
        name: 'ADMINISTRACION',
        src: 'https://cdn-icons-png.flaticon.com/512/2219/2219519.png',
        description: ''
    },
    {
        id: 4,
        name: 'ELECTRONICA',
        src: 'https://cdn-icons-png.flaticon.com/512/2432/2432746.png',
        description: ''
    },
    
];

const RoomList = ({ query, isNavOpen, setIsNavOpen }) => {
    const debouncedSearch = useDebounce(query, 350);
    const { joinRoom } = useChatActions();
    const { currentRoom, setCurrentRoom, userName } = useChat();


    const filteredRooms = useMemo(() => {
        const filter = rooms.filter(room => {
            const includesCaseInsensitive  = {
                name: room.name.toLowerCase(),
                description: room.description.toLowerCase()
            };
    
            const { name, description } = includesCaseInsensitive;
    
            return name.includes(debouncedSearch.toLowerCase()) || description.includes(debouncedSearch.toLowerCase());
        });

        return filter;
    }, [debouncedSearch]);

    const handleRoomClick = (roomID) => {
        if(currentRoom?.id === roomID) {
            return;
        }

        const selectedRoom = rooms.find(room => room.id === roomID);
        setCurrentRoom(selectedRoom);

        joinRoom({ roomID, userName });

        setIsNavOpen(false);
    }
    

    return (
        <RoomListContainer open={ isNavOpen }>
            <h3>Grupos</h3>

            <ul>
                {   
                    
                    filteredRooms.map(room => {
                        const { id, name, src, description} = room;

                        return (
                            <RoomItem className=" text-white " active={ currentRoom?.id === id } key={ id } onClick={ () => handleRoomClick(id) }>
                                <img alt='room-img' src={ src } />

                                <div>
                                    <span>{ name }</span>
                                    <div className=" text-white text-xs">{ description }</div>
                                </div>
                            </RoomItem>
                        );
                    })
                }
            </ul>
        </RoomListContainer>
    );
};

export default RoomList;