import { useState, useEffect } from 'react';
import { AuthFetch } from '../services/api';

// const urlBase = process.env.REACT_APP_BEEDRONE_WEB_API;

export const useEstados = () => {
    const [ estados, setEstados ] = useState([]);

    
    useEffect(() => {
        listarEstados();

    }, []);

    const listarEstados = async () => {
        const estadosEstaticos = [
            {codEstado: 5,nomEstado: "NUEVO",abrevEstado: "NUE"},
            {codEstado: 6,nomEstado: "PROCESO",abrevEstado: "PRO"},
            {codEstado: 7,nomEstado: "ATENDIDO",abrevEstado: "ATE"},
            {codEstado: 8,nomEstado: "RECHAZADO",abrevEstado: "REC"}
        ]
        setEstados(estadosEstaticos)
        // const response = await AuthFetch('/Query/estadosPeticion')
        // if (response.isValid) {
        //     setEstados(response.content)
        // }
    }

    return [ estados ];
}

