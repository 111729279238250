import React, { useRef } from 'react';
import styled from 'styled-components';
import useChatActions from '../../../hooks/chat/useChatActions';
import { useChat } from '../../../context/provider/ChatProvider';

const MessageForm = styled.form`
    display: flex;
    align-items: center;
`;

const ChatForm = () => {
    const inputRef = useRef(null);
    const { sendMessage } = useChatActions();
    const { currentRoom, userName } = useChat();

    const onSubmit = (e) => {
        e.preventDefault();

        sendMessage(
            inputRef.current.value, 
            currentRoom.id, 
            userName
        );
        
        inputRef.current.value = '';
        inputRef.current.focus();
    }

    return (
        <MessageForm onSubmit={ onSubmit }>
            <input className='rounded text-black p-3 mr-2 caret-black w-full h-[80%] ' type="text" placeholder='Escriba su mensaje aqui' ref={ inputRef }/>
            <button className='text-white bg-green-600 text-3xl fa fa-telegram px-2 rounded'></button>
        </MessageForm>
    );
};

export default ChatForm;